import React from 'react';
import Link from 'next/link';
import { useRouter, } from 'next/router';
import { useEffect, useState } from 'react';
import { supabase_public } from '../lib/supabaseClient';
import Image from 'next/image';
import { useContext } from 'react'
import { PageContext } from '../contexts/pageContext';
import UserConversations from './UserConversations';
import AuthPrompt from './AuthPrompt';
import { FaTiktok, FaInstagram, FaTwitter, FaDiscord } from 'react-icons/fa6';
import { TbUsers, TbUserSearch } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";

const API_URL = process.env.NEXT_PUBLIC_API_URL;
const grad_color = {
  background: "linear-gradient(90deg, #007CF0 0%, #32B7E4 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  // textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  };

const EmailIcon = ({ email }) => {
  const firstLetter = email.charAt(0).toUpperCase();
  return (
    <div
    className="w-6 h-6 bg-blue-500 text-white text-md items-center justify-center rounded-full inline-flex mr-1">
      {firstLetter}
    </div>
  );
};
function HistorySidebar({loggedInUsername, userid, sessionUUID, showAuthPrompt, setShowAuthPrompt, topicSelected, children, feedbackOpened, setFeedbackOpened}) {

  const context = useContext(PageContext)
  const [isOpen, setIsOpen] = useState(true);
  const [session, setSession] = useState(null)
  const [email, setEmail] = useState(null)
  // const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [status, setStatus] = useState(null)
  const [showTooltip, setShowTooltip] = useState(false);

  const buttonStyle = "hover:bg-gray-200 rounded-xl cursor-pointer active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-600"


  useEffect(() => {
    async function fetchAllData() {
        const { data, error } = await supabase_public.auth.getSession();
        if (error) {
            console.log("error retrieving session");
            // setShowAuthPrompt(true);
            return;
        }


        if (data.session && data.session.expires_at * 1000 < Date.now()) {
            console.log(data.session.expires_at * 1000, Date.now())
            supabase_public.auth.signOut();
            setSession(null);
            console.log("session expired")
            // setShowAuthPrompt(true);
            return;
        }

        setSession(data);
        const email = data?.session?.user?.email;
        if (email) {
            // Set the email
            setEmail(email);
            if (email.includes("fateen")) {
              alert("Fateen. You must launch.")
            }
        }
        // if (!data || data.session == null) {
        //     setShowAuthPrompt(true);
        // }
    }
    fetchAllData();
}, [userid]); // Empty dependency array ensures this runs once on mount

  const handleSignOut = async () => {
    console.log("signing out")
    await supabase_public.auth.signOut()
    window.location.reload(); // Reload the page on redirect
  }
  return (
    <div className="flex w-screen  h-screen overflow-hidden min-w-screen  ">
      <div className={`flex p-4 flex-col flex-shrink-0 transition-all duration-300 bg-cardBg border-subtleBorder ${isOpen ? 'w-56' : 'w-14'}`}>
      <div className={` flex items-center justify-center pt-4`}>
          { (context.isMainPage) && (
            <>

                <a href="/" className="flex justify-center">
                    <span className=" justify-center self-center align-center mx-auto text-4xl font-semibold whitespace-nowrap  text-bluelogo ">Claros</span>
                </a>
            </>
          ) }
      </div>

      {showAuthPrompt && <AuthPrompt sessionId={sessionUUID} showCloseButton={true} setShowAuthPrompt={setShowAuthPrompt} topicSelected={topicSelected}/>}

      <div className="flex justify-center items-center py-6">
      <button className="flex items-center text-textGray bg-bgwhite rounded-lg border-2 p-2  mx-auto w-full text-center justify-center transition duration-200 ease-in-out transform hover:scale-105 tracking-normal font-sans" onClick={() => {
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        window.location.href = ref ? `/${ref}` : '/';
      }}>
        <div> New Search</div></button>
      </div>

      {!session || session.session == null && (
        <div className="flex justify-center items-center">
                    <a className="underline text-[15px] hover:text-lighten transition duration-200 ease-in-out transform text-textGray tracking-normal hover:text-bluelogo cursor-pointer" onClick={() => setShowAuthPrompt(true)}>
                      Sign in to see history
                    </a>
        </div>
      )}

      {session && session.session != null && (
        <>
        <h1 className="text-xl mb-[15px] text-textGray">History</h1>
          <div className="flex w-full items-start space-x-8 ">
            <div className="w-full rounded-xl ">
              <div className="overflow-y-scroll max-h-[50vh]">
                <UserConversations setSelectedConversation={selectedConversation} uid={userid} />
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mt-auto"> {/* This ensures that the bar stays at the bottom */}
    <div className="flex flex-col  bg-transparent">
        {/* <span className='pb-[10px]'>
                 <a className="text-bluelogo text-bold hover:text-bluelink text-[11px] " href="https://claros.so/landingpage#pricing">Want this on your storefront? </a>
        </span> */}

<button onClick={() => { console.log('Feedback button clicked'); setFeedbackOpened(true); }} className="items-center mx-auto w-full text-center justify-center transition duration-200 transform tracking-normal font-sans hover:bg-gray-200 rounded-lg cursor-pointer active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-600 border-2 ">

          <FaClipboardList className='mr-2 text-center' size={12}/>
          <span className="text-sm"> Leave Feedback </span>
        </button>


        <div className="flex justify-between items-center pt-2 text-textGray">
        {session && session.session && (
          <div className="flex items-center justify-between w-full">
            {loggedInUsername ? (
              <>
                <a href={`/profile/${loggedInUsername}`} className={buttonStyle}>
                  <TbUsers size={20} />
                  <div className="text-align-center relative ml-[3px]">Profile</div>
                </a>
                <button className={buttonStyle} onClick={handleSignOut}>
                  <MdLogout size={20} />
                </button>
              </>
            ) : (

              <button className="ml-auto" onClick={handleSignOut}>
                <img src="/images/User/sign-out.svg" className="h-5 w-5" alt="Sign Out" />
              </button>
            )}
          </div>
        )}
        </div>



        {/* Download and Social Links */}
        <div className="flex justify-center items-center gap-4 py-2 ">
          {/* <button
              type="button"
              className="flex items-center gap-2 px-3 py-1 rounded-full text-sm relative"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
          >
              <FaMobileScreenButton className="text-textGray" />
              <span className=' text-textGray'>Download</span>
              {showTooltip && (
                  <div className="  absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 p-2 bg-white text-gray-600 text-xs rounded-md shadow-lg">
                      Go to shop.claros.so on mobile and download to homescreen
                  </div>
              )}
          </button> */}
            <a href="https://twitter.com/so_claros" className="rounded-full p-2 text-textGray hover:text-bluelogo " target="_blank">
                <FaTwitter/>
            </a>
            <a href="https://discord.gg/87KJvjpkF8" className="text-textGray  rounded-full p-2 hover:text-bluelogo" target="_blank">
                <FaDiscord/>
            </a>
            <a href="https://www.tiktok.com/@claros_so" className="text-textGray  rounded-full p-2 hover:text-bluelogo" target="_blank">
                <FaTiktok/>
            </a>
            <a href="https://www.instagram.com/claros_ai/" className="text-textGray  rounded-full p-2 hover:text-bluelogo" target="_blank">
                <FaInstagram/>
            </a>
        </div>
    </div>
</div>


    </div>
    <div className="flex-1 flex-grow bg-bgwhite overflow-y-scroll">

        {children}
      </div>
    </div>
  );
}

export default HistorySidebar;
