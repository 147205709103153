import { ChatInput } from './ChatInput';
import { ChatInputBar } from './ChatInputBar';
import { ChatMessage } from './ChatMessage';
import { FeedbackModal } from '../FeedbackModal';
import { getAmazonDomain } from '../utils/Domains';
import axios from "axios";
import React, { useState, useEffect, useRef } from 'react';
import { RangeSlider, Loader, Progress } from '@mantine/core';
import styles from "../../styles/Search.module.css";
import posthog from 'posthog-js';
import { useCookies } from 'react-cookie'
import { Refresh, Flag } from 'tabler-icons-react';
import { ActionIcon } from '@mantine/core';
import { ShareButton } from '../ShareButton'
const COOKIE_NAME_ID = 'product-help-chat-gpt3'
const COOKIE_NAME_COUNTRY = 'product-help-chat-gpt3-country'
const API_URL = process.env.NEXT_PUBLIC_API_URL;

import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import ProductCarousel from '../products/ProductCarousel';
import { translationsDone, translationsLastChat } from '../utils/translations';
import {EMOJI_DICT, findEmoji} from '../utils/Emojidict'
import {Sort} from '../products/Sort';
import { DomainFilter } from '../products/DomainFilter';
import { FaForward, FaBookmark } from "react-icons/fa";
import SourcePill from '../sources/SourcePill';
import SourcePillSmall from '../sources/SourcePillSmall';
import QuestionCard from './QuestionCard';


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ChatPane(props: any) {
    const [query, setQuery] = useState(props.query || "")
    const [undoLog, setUndoLog] = useState(false)
    const [numUndo, setNumUndo] = useState(0)
    const [qapair, setQapair] = useState(props.qapair || [])
    const [questions, setQuestions] = useState([])
    const [allOptions, setAllOptions] = useState(() => {
        if (props.chats && Array.isArray(props.chats)) {
          return props.chats.map(chat => chat.options || []);
        } else {
          return [];
        }
    });
    const [options, setOptions] = useState(props.options || [])
    const [isDone, setIsDone] = useState(false); // Add this line
    const [loaderVisible, setLoaderVisible] = useState(false)
    const [inlineProducts, setInlineProducts] = useState(props.inlineProducts || [])
    const bottomRef = useRef<HTMLDivElement>(null);
    const youRef = useRef<HTMLDivElement>(null);
    const botRef = useRef<HTMLDivElement>(null);
    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME_ID, COOKIE_NAME_COUNTRY])
    const [psearch, setPsearch] = useState('');
    const [userScrolled, setUserScrolled] = useState(false);
    const chatContainerRef = useRef(null);
      // State to store the selected range values for each product group
    const [range, setRange] = useState([]);

    const [uid, setUser] = useState(null)
    const [sortType, setSortType] = useState('');

    const CHATS_BEFORE_RECS_START = 2

    const context = useContext(PageContext)
    const [webRefs, setWebRefs] = useState([]);
    const [redditRefs, setRedditRefs] = useState([]);
    const inputRef = useRef(null);
    const [progressMessages, setProgressMessages] = useState({ message: 'Thinking...', value: 25 });
    const [domainFilter, setDomainFilter] = useState([]);
    const [ isDomainModalOpen, setIsDomainModalOpen] = useState(false);

    const [ isProductsMsg, setIsProductsMsg] = useState(false);
    const [ isSending, setSending] = useState(false);
    const [productDescriptionMap, setProductDescriptionMap] = useState({})


    // const [showAuthPrompt, setShowAuthPrompt] = useState(false);

    useEffect(() => {
        if(loaderVisible && isSending ) {
            setTimeout(() => {
                setProgressMessages({message : "Asking online for recommendations...", value: 33})
                setTimeout(() => {
                    setProgressMessages({message : "Searching Amazon...", value: 55})
                    setTimeout(() => {
                        setProgressMessages({message : "Asking our friends on Reddit...", value: 88})
                    }, 4000);
                }, 2000);
            },2000);
        }
    }, [loaderVisible]);


    useEffect(() => {
        if (inlineProducts.length < 1) {
            return;
        }

        //get products up to the last one
        const prevProducts = inlineProducts.slice(0, -1);
        const lastProducts = inlineProducts[inlineProducts.length - 1];
        const updatedLastProducts = [...lastProducts];
        console.log("passed products", inlineProducts)
        console.log("product description map", productDescriptionMap)

        if (lastProducts.length > 0){
            for (let i=0; i < lastProducts.length; i++) {
                const product = lastProducts[i];
                if (productDescriptionMap.hasOwnProperty(product.detailPageURL)) {
                    //update the object with aisummary
                    updatedLastProducts[i].aiSummary = productDescriptionMap[product.detailPageURL]
                    console.log("updated product", updatedLastProducts[i])
                } else {
                }
            }
            console.log("updated last products", updatedLastProducts)
            setInlineProducts([...prevProducts, updatedLastProducts]);
        }
        //setInlineProducts(updatedProducts);
    }, [productDescriptionMap]);




    function reload() {
        if (window.location.href.includes('/threads')) {
            // Navigate to the origin (base URL)
            window.location.href = window.location.origin;
        } else {
            // Reload the current page
            window.location.reload();
        }
    }

    function retryChat() {
        if (qapair.length === 1) {
            // Reload the page
            reload()
        } else {
            // Remove the last chat from qapair
            setUndoLog(true)
            setNumUndo(numUndo + 1)

            const newQapair = qapair.slice(0, -1);
            setOptions( allOptions[ newQapair.length -1 ] )
            setQapair(newQapair);
            // Remove the latest set of products from inlineProducts
            if (inlineProducts.length > 0) {
                const newInlineProducts = inlineProducts.slice(0, -1);
                setInlineProducts(newInlineProducts);
            }
            // Reset the query and any other necessary state variables
            setQuery('');
            // Reset other state variables as needed
        }
    }

    const sanitizePrice = (priceStr) => {
        if (typeof priceStr !== 'string') {
            priceStr = priceStr.toString();
        }
        if (!priceStr) return 0; // If undefined or empty
        const sanitized = priceStr.replace(/[^\d.]/g, '');  // Removes all non-numeric characters except for decimal points
        return parseFloat(sanitized);
    }



    const getMinMaxPriceForGroup = (products) => {
        let min = Infinity;
        let max = -Infinity;
        if (!isDone) {
            products.forEach(product => {
                const price = sanitizePrice(product.price);
                if (price < min) min = price;
                if (price > max) max = price;
            });
        }

        return [min, max];
    }

    function updateRecs(rec) {
        props.updateRecs(rec)
    }

    useEffect(() => {
        if (!chatContainerRef.current) return; // Exit if the ref is not attached

        const handleScroll = () => {
            const isNearBottom = chatContainerRef.current.scrollHeight - chatContainerRef.current.scrollTop <= chatContainerRef.current.clientHeight + 10;

            setUserScrolled(!isNearBottom);
        };

        chatContainerRef.current.addEventListener('scroll', handleScroll);
        return () => {
            if (chatContainerRef.current) { // Also check here to avoid potential issues
                chatContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    function showRecs() {
        // console.log("Show Recs", qapair.length)
        return (qapair.length >=  (CHATS_BEFORE_RECS_START) && !isDone)
        // return (qapair.length >=  (CHATS_BEFORE_RECS_START) && !isDone && inlineProducts[inlineProducts.length -1] && inlineProducts[inlineProducts.length -1].length > 0)
    }

    function showSortAndBudget() {
        return inlineProducts[inlineProducts.length -1] && inlineProducts[inlineProducts.length -1].length > 0
    }

    async function handleSkipToRecs() {
        submitSearch('Please skip this question', qapair, props.topic, true);
    }

    async function handleProductSpecificSearch(query) {
        submitSearch(query, qapair, props.topic, true);
    }


    async function handlechat(query, qapair, options, response, recs, topic, sessionId, storeId, undo=false){
        let timeStamp = Date.now()
        posthog.capture('chat generated', {
            query: query,
            history: qapair,
            options: options,
            response: response,
            recs: recs,
            topic: topic,
            sessionId: sessionId,
            timeStamp: timeStamp,
            storeId: storeId,
            undo: undo
        })
        await fetch("/api/telemetry/log", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: query,
                history: qapair,
                options: options,
                response: response,
                recs: recs,
                topic: topic,
                uid: uid,
                timestamp: timeStamp,
                sessionId: sessionId,
                storeId: storeId,
                undo: undo,
                numUndo: numUndo
            }),
        })
        setUndoLog(false)
        setNumUndo(0)
    }


    const productButtonStyles = {
        awsBtn: {
            '--button-secondary-color': '#ebfbee',
            '--button-secondary-color-dark': '#40c057',
            /* --button-secondary-color-light: #1e88e5; */
            '--button-secondary-color-hover': '#d8eddc',
            '--button-secondary-color-active': '#bed1c2',
            '--button-secondary-border': '2px solid #40bf56',
            '--button-default-height': '100px',
            width: '100%',

        }
    };

    useEffect(() => {
        setDomainFilter([])
    }, [inlineProducts])


    async function checkTopic(topic) {
        await fetch(API_URL + '/checkTopic' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ topic })
        })
        .then(response => response.json())
        .then(result => {
            // handle the result here
            return result.topic;
        })
        .catch(error => {
            // handle the error here
        });
    }



    async function initChat(topic) {
        console.log("init chat")
        if (topic != ""){
            setLoaderVisible(true)
            await submitSearch(query, qapair, topic)
            setLoaderVisible(false)
            if (! props.uid) {
                props.setShowAuthPrompt(true)
            }

        }
    }

    useEffect(() => {
        if (!cookies[COOKIE_NAME_ID]) {
          // generate a semi random short id
          const randomId = Math.random().toString(36).substring(7)
          setCookie(COOKIE_NAME_ID, randomId)
        }
        if (!cookies[COOKIE_NAME_COUNTRY]) {
            // generate a semi random short id
              axios
                  .get("https://ipapi.co/json/")
                  .then((response) => {
                      let data = response.data;
                      console.log(data.country_code)
                      let country = getAmazonDomain(data.country_code)
                      console.log("Setting marketplace to search to " + data.country_code)
                      setCookie(COOKIE_NAME_COUNTRY, country)
              }).catch((error) => {
                  console.log(error);
              });
          }
      }, [cookies, setCookie])


    useEffect(() => {
        console.log("topic selected", props.topicSelected)
        if (props.topicSelected && qapair.length == 0) {
            initChat(props.topic)
        }
    }, [props.topicSelected])

    // useEffect(() => {
    //     setProgressMessages([])
    // }, [  loaderVisible ])




    async function generateRecs(query, qapairpassedin, topic, skipToRecs) {
        if (props.makeProductsAtChat) {
                console.log("qapairpassedin", qapairpassedin)
                let returned_questions = []
                if (qapairpassedin.length === 0 || questions.length < 3) {

                    const response = await fetch(`${API_URL}/questions`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            topic: topic,
                            language_option: '',
                            store_id: context.storeId,
                            language: context.language
                        }),
                    });
                    if (!response.ok) {
                        throw new Error(response.statusText)
                    }
                    const data = await response.json();

                    returned_questions = data["questions"]
                    setQuestions(returned_questions)
                }

                if (qapairpassedin.length === 0 && !context.isMainPage ) {

                    const response = await fetch(`${API_URL}/examples`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            topic: topic,
                            store_id: context.storeId,
                        }),
                    });
                    if (!response.ok) {
                        throw new Error(response.statusText)
                    }
                    const data = await response.json();
                }
                if ( skipToRecs || qapair.length  >=  (CHATS_BEFORE_RECS_START) ) {
                    // console.log("how many", qapairpassedin)
                    // setProgressMessages({message : "Asking online for recommendations...", value: 33})
                    setSending(true)
                    await axios.post(context.shopUrl,{
                        topic: topic,
                        history: [...qapairpassedin, [query]],
                        store_id: context.storeId,
                        marketplace: cookies[COOKIE_NAME_COUNTRY] || "US",
                        language: context.language,
                        psearch: psearch,
                        skipToRecs: true,
                        profile: props.profile
                    }).then( async (response) => {
                        // console.log(response.data)
                        let parsedResponse = JSON.parse(response.data); // Assuming response.data is a JSON string; otherwise, you can skip this step
                        let returnedSearchQuery = parsedResponse.searchQuery;
                        let returnedProducts = parsedResponse.results;
                        let web_recs = parsedResponse.web_recs || [];
                        let reddit_recs = parsedResponse.reddit || [];
                        setIsProductsMsg(true)
                        setWebRefs(web_recs)
                        setRedditRefs(reddit_recs)
                        // setProgressMessages({message: "Looking across Amazon...", value: 66})

                        await setInlineProducts(prevProducts => [...prevProducts, returnedProducts]);


                        const chatRes = await generateChat(query, qapairpassedin, topic, returnedProducts, web_recs, returned_questions, props.numberOfInlineProducts, returnedSearchQuery, skipToRecs || showRecs());

                        // await setpsearch(newpsearch)

                        setQapair(chatRes.newHistory)
                        setOptions(chatRes.newOptions)
                        setAllOptions([...allOptions, chatRes.newOptions])

                        // console.log("jsonProducts", returnedProducts)
                        props.setPassedProducts(returnedProducts)

                    // do we need this set again??
                    //setInlineProducts(prevProducts => [...prevProducts, returnedProducts]);


                        handlechat(query, chatRes.newHistory, chatRes.newOptions, chatRes.lastMessage, returnedProducts, topic, props.sessionUUID, context.storeId, undoLog)
                        setSending(false)
                        // updateProgress("Analyzing results")
                    })
                    .catch((error) => {console.log(error)});
                } else {
                    let jsonProducts = []
                    let returnedProducts = []
                    let web_recs = []
                    let searchQuery = ""

                    await setInlineProducts(prevProducts => [...prevProducts, returnedProducts]);
                    const chatRes = await generateChat(query, qapairpassedin, topic, returnedProducts, web_recs, returned_questions, props.numberOfInlineProducts,  searchQuery, skipToRecs || showRecs());
                    //set all states (these are async btw)
                    setIsProductsMsg(false)

                    setQapair(chatRes.newHistory)
                    setOptions(chatRes.newOptions)
                    setAllOptions([...allOptions, chatRes.newOptions])


                    props.setPassedProducts(jsonProducts)

                    handlechat(query, chatRes.newHistory, chatRes.newOptions, chatRes.lastMessage, jsonProducts, topic, props.sessionUUID, context.storeId, undoLog)
                }
            }
    }

    const handleScroll = () => {
        setUserScrolled(true);
      };

    useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, []);

    useEffect(() => {

        if (!userScrolled) {
            if (botRef.current){
                // console.log("ref there")
                if (false && window.CSS && CSS.supports('scroll-behavior: smooth')) {
                    botRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    const topPosition = botRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.parent.postMessage({
                       type: "iframeScroll",
                       top: topPosition,
                    }, "*");

                  } else {
                    // Fallback for browsers that do not support smooth scrolling
                    // console.log("falling back")

                    const topPosition = botRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({ top: topPosition, behavior: 'smooth' });
                    window.parent.postMessage({
                       type: "iframeScroll",
                       top: topPosition,
                    }, "*");

                  }
            } else {
                // TODO: test this and see how ppl like it
                if (false && window.CSS && CSS.supports('scroll-behavior: smooth')) {
                    bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    const topPosition = bottomRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.parent.postMessage({
                       type: "iframeScroll",
                       top: topPosition,
                    }, "*");
                  } else {
                    // Fallback for browsers that do not support smooth scrolling


                    const topPosition = bottomRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({ top: topPosition, behavior: 'smooth' });
                    window.parent.postMessage({
                       type: "iframeScroll",
                       top: topPosition,
                    }, "*");
                }
            }
        }
    }, [qapair]);


    async function generateChat(query, history, topic, recs, web_recs, returned_questions, numberOfInlineProducts, searchQuery, showRecs) {

        let passed_question = ''
        let all_questions = []
        if( questions.length === 0 ) {
            // console.log("we in here")

            passed_question = returned_questions[history.length]
            all_questions = returned_questions
        } else {
            passed_question = questions[history.length]
            all_questions = questions
        }
        // console.log("Recs display condition", qapair.length)
        // console.log("Recs display condition", qapair.length  >=  chats_b4_recs_start ? true: false)
        const chattestresponse = await fetch(context.chatUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: query,
                history: history,
                topic: topic,
                recs: recs,
                web_recs: web_recs,
                question:  passed_question,
                questions: all_questions,
                user: cookies[COOKIE_NAME_ID],
                language: context.language,
                skip: showRecs,
                numberOfInlineProducts: props.numberOfInlineProducts,
                searchQuery: searchQuery,
                isB2C: context.isMainPage,
                profile: props.profile,
                reddit: redditRefs
            }),
        })


        // await setInlineProducts(prevProducts => [...prevProducts, data.returnedProducts]);


        if (!chattestresponse.ok) {
            throw new Error(chattestresponse.statusText)
        }

        const data = chattestresponse.body
        if (!data) {
            return
        }
        const reader = data.getReader()
        const decoder = new TextDecoder()
        let done = false
        let optionsStarted = false
        let lastMessage = ''
        let options = ''




        while (!done) {
            const { value, done: doneReading } = await reader.read()
            done = doneReading
            const chunkValue = decoder.decode(value)

            if (optionsStarted) {
                options = options + chunkValue
            } else if (chunkValue.includes("####")) {
                optionsStarted = true
                options = options + chunkValue.split("####")[1]
            } else {
                if (lastMessage == '') {
                    setUserScrolled(false);
                }
                lastMessage = lastMessage + chunkValue
                setQapair([...history, [query, lastMessage] ])
                // setProgressMessages({ message: 'Thinking...', value: 10 })
                setProgressMessages({ message: 'Thinking...', value: 10 });
                setLoaderVisible(false)

                // console.log("deciding ref")

            }

            if (!userScrolled) {
                let targetRef = botRef.current ? botRef.current : bottomRef.current;

                if (targetRef) {
                    const topPosition = targetRef.getBoundingClientRect().top + window.pageYOffset;

                    if (window.CSS && CSS.supports('scroll-behavior: smooth')) {
                        targetRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    } else {
                        console.log("falling back");
                        window.scrollTo({ top: topPosition, behavior: 'smooth' });
                    }
                }
            }



        }
        let qapairnew2 = [...history, [query, lastMessage]]
        setQapair(qapairnew2)

        let filtered_options = options.split('||').filter(function (el) {
            return Boolean(el.trim());
        });

        var newOptions;
        if (filtered_options.length == 0) {
            let optionresponse = await axios.post(context.optionsUrl, { history :  qapairnew2, language: context.language, topic: topic})
            .catch(err => err.response);
            if (optionresponse.data.options){
                newOptions = optionresponse.data.options; // take first 4 options
            }
        } else {
            newOptions = filtered_options; // take first 4 options
        }

        newOptions = newOptions.slice(0,3)
        if (newOptions.length === 1 && newOptions[0].includes('||')) {
            newOptions = newOptions[0].split('||');
        }
        if (newOptions.length === 1 && newOptions[0].includes('\n')) {
            newOptions = newOptions[0].split('\n');
        }
        if (newOptions.length === 1 && newOptions[0].includes(',')) {
            newOptions = newOptions[0].split(',');
        }
        console.log("some preprocessing options", newOptions)
        newOptions = newOptions.map(option => {
            // If option starts with '-', remove it
            if (option.startsWith('-')) {
              option = option.slice(1);
            }
            // If option starts with ' \n-', remove it
            if (option.startsWith(' \n-')) {
                option = option.replace('\n-', '');
            }
            // If option contains '"', remove it
            if (option.includes('"')) {
              option = option.replace(/"/g, '');
            }
            // If option contains '"', remove it
            if (option.includes('||')) {
                option = option.replace('||', '');
            }
            option = option.replace('||', '');
            option = option.replace(/\n\d+\. /, '');
            option = option.replace(/Option \d+: /, '');
            option = option.replace(/option \d+: /, '');
            option = option.replace(/^\d+\.(?=\s|$)/, '');
            option = option.replace(/\[/g, '');
            option = option.replace(/\]/g, '');
            return option;
        });
        console.log("more preprocessing options", newOptions)
        newOptions = newOptions.filter(option => option !== "" && option !== " ");

        if(qapair.length > 3) {
            newOptions.push( translationsDone[context.language] );
        }
        return {
            lastMessage: lastMessage,
            newHistory: qapairnew2,
            newOptions: newOptions
        }
    }

    async function submitSearch(query, qapairpassedin, topic, skipToRecs = false) {
        setIsProductsMsg(false)


        if(Object.values(translationsDone).includes(query)) {
            setIsDone(true);
            // You can set your prewritten message here. Assuming setQapair is the function to set chat messages.
            setQapair([...qapairpassedin, [query, translationsLastChat[context.language]]]);

            let timeStamp = Date.now()
            posthog.capture('chat done', {
                topic: topic,
                sessionId: props.sessionUUID,
                timeStamp: timeStamp,
                storeId: context.storeId
            })
            //lowkey this could work too
            // posthog.capture('chat generated', {
            //     query: query,
            //     history: qapair,
            //     options: options,
            //     response: response,
            //     recs: recs,
            //     topic: topic,
            //     sessionId: sessionId,
            //     timeStamp: timeStamp,
            //     storeId: storeId
            // })

            if (!userScrolled) {
                if (bottomRef && bottomRef.current) {
                    // Check if the browser supports smooth scrolling
                    if (window.CSS && CSS.supports('scroll-behavior: smooth')) {
                    bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    } else {
                    // Fallback for browsers that do not support smooth scrolling

                    const topPosition = bottomRef.current.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({ top: topPosition, behavior: 'smooth' });
                    }
                }
            }

            setTimeout(() => {
                props.setFeedbackOpened(true); // Assuming that props.setFeedbackOpened is a function to open the feedback
            }, 3000);

            //we need to call handleChat with correct stuff so it get's logged.
            return;
        }
        setLoaderVisible(true)
        setRedditRefs([])

        setOptions(new Array(options.length))

        let res = await generateRecs(query, qapairpassedin, topic, skipToRecs)

        console.timeEnd("total")
        return
    }

    return (
        <div >
                {/* <LoadingOverlay visible={loaderVisible} /> */}
            <div >
                <div className={styles.form} style={{ textAlign: 'center', paddingLeft: "10%", paddingRight: "5%" }}>
                </div>
                {props.feedbackOpened && <FeedbackModal opened={props.feedbackOpened} setOpened={props.setFeedbackOpened} />}


            {/* {context.isMainPage && (props.session?.session === null && props.session !== undefined && showAuthPrompt) && <AuthPrompt sessionId={props.sessionUUID} showCloseButton={true} setShowAuthPrompt={setShowAuthPrompt}/>} */}
            {/* <div className="max-w-[900px] mx-auto "> */}
                {/* "See Past Conversations" Tab */}
                {/* <div
                onClick={handlePastConversationsClick}
                className="fixed top-1/2 left-0 transform -translate-y-1/2 bg-light-blue-200 px-4 py-2 rounded-r-lg cursor-pointer">
                    <img src="/images/User/past.svg" alt="" className='mr-2 w-[20px] h-[20px]' />  Past Conversations
                </div> */}
                {/* ... other elements ... */}
            {/* </div> */}

            {/* <ScrollToBottom> */}

            {/* Show topic if it exists */}
            {props.topic && (
                <div className="flex justify-between py-4 font-bold text-2xl mx-2 md:mx-12 px-4 mt-4 max-w-full">
                    <div className="items-center flex  cursor-pointer pb-1">
                        {capitalizeFirstLetter(props.topic)} {findEmoji(props.topic)}
                        {props.isMobile && (
                            <ShareButton sessionId={props.sessionUUID} isMobile={props.isMobile} />
                        )}
                    </div>
                    <div className='flex items-center'> {/* Flex container for buttons */}
                        {!props.isMobile && (
                            <ShareButton sessionId={props.sessionUUID} isMobile={props.isMobile} />
                        )}
                    </div>
                </div>
            )}
            {
                (inlineProducts && qapair) && (
                    qapair.map((pair, idx) => {
                        const [minPrice, maxPrice] = getMinMaxPriceForGroup(inlineProducts[idx]);
                        let sortedProducts = []
                        let allProducts = []
                        if (!isDone) {
                            allProducts = inlineProducts[idx]
                            sortedProducts = inlineProducts[idx].filter(product => {
                            const productPrice = sanitizePrice(product.price);
                            return productPrice >= (range[idx]?.[0] || minPrice) && productPrice <= (range[idx]?.[1] || maxPrice);
                        });
                        }
                        return (
                            <>
                            <div ref={chatContainerRef} className={styles.output} key={idx}>

                                {/* <div className={styles.chatbubbleyou} ref={youRef}>
                                    <div className="rounded-xl bg-blueoptionshover max-w-[700px] mx-auto">
                                    {pair[0] && (
                                        <>
                                        <ChatMessage message={pair[0]} />
                                        </>
                                    )}
                                    </div>
                                </div> */}

                                {/* { idx === (qapair.length - 1) && isProductsMsg && redditRefs &&(
                                    <div className='ml-4 md:ml-12 mb-2' >
                                    <div className='inline-flex'>  <div className="text-align-center relative mx-[3px] mb-1"> <h3 className='text-[20px]'>Ask Reddit</h3> </div> <FaBookmark size={20} className='text-bluelink'/> </div>
                                    <div className='block'>
                                    { redditRefs.slice(0, 3).map((reddit, index) => (
                                            <SourcePillSmall key={index} src={reddit} />
                                    ))}
                                    </div>
                                </div>)} */}


                                {(  showSortAndBudget()) && (
                                    <>



                                        <div >
                                            <div className='flex flex-wrap   max-w-[100vw]  md:mx-auto  md:max-w-[79vw]'>
                                            {idx === (qapair.length - 1) && !loaderVisible && (
                                                    <>
                                                    <DomainFilter
                                                        products={allProducts}
                                                        domainFilter={domainFilter}
                                                        setDomainFilter={setDomainFilter}
                                                        isDomainModalOpen={isDomainModalOpen}
                                                        setIsDomainModalOpen={setIsDomainModalOpen}
                                                        />
                                                    <div className="flex items-center mx-auto pt-4">
                                                        <Sort setSortType={setSortType} />
                                                    </div>
                                                    </>
                                                )}
                                                {idx === (qapair.length - 1) && showRecs && !loaderVisible && (
                                                    <div className="flex items-center w-[250px] md:w-[350px] text-gray-500  pt-4 pl-4 max-w-[700px] mx-auto text-sm">
                                                        <span>Price: </span>
                                                        <RangeSlider
                                                        color="rgb(58, 133, 194)"
                                                            min={minPrice}
                                                            max={maxPrice}
                                                            value={range[idx] || [minPrice, maxPrice]}
                                                            onChange={(value) => {
                                                                let newRange = [...range];
                                                                // Format the values to have up to two decimal places
                                                                newRange[idx] = value.map(val => Number(val));
                                                                setRange(newRange);
                                                            }}
                                                            className="ml-2.5 flex-grow z-0"
                                                            labelAlwaysOn
                                                            precision={0}
                                                        />
                                                    </div>
                                                )}

                                            </div>

                                                <ProductCarousel products={sortedProducts} sort={sortType} isMobile={props.isMobile} handleProductSpecificSearch={handleProductSpecificSearch} domains={domainFilter} last={ idx === (qapair.length - 1) } />
                                        </div>
                                    </>
                                )}


                                   {/* <div className={styles.chatbubblebot}>
                                    <div className="flex items-center max-w-[700px] mx-auto">

                                        <ChatMessage isBot={true} message={pair[1]} isCurr={ (idx == (qapair.length -1)) ? true: false } retry={retryChat} idx={idx} sessionId={props.sessionUUID} setFeedbackOpen={props.setFeedbackOpened}/>

                                    </div>

                                    </div> */}

                            </div>


                            {idx === (qapair.length - 1) && !loaderVisible &&
                            (
                            <QuestionCard message={pair[1]}
                                 topic={props.topic}
                                 handleSubmit={submitSearch}
                                 query={query}
                                 updateQuery={setQuery}
                                 qapair={qapair}
                                 options={options}
                                 isMobile={props.isMobile}
                                 inputRef={inputRef}
                                 isProductsMsg={isProductsMsg}
                                 handleSkipToRecs={handleSkipToRecs}
                                 setProductDescriptionMap={setProductDescriptionMap}
                            /> )
                            }


                            </>
                        );

                    })
                )
            }


        {/* <div className="flex flex-col items-center justify-center pt-5 mx-auto">
            {redditRefs.slice(0, 4).map((reddit, index) => (
                <div key={index} className="animate-fade-in-down">
                    <SourcePill src={reddit} />
                    <div className="text-center text-sm italic my-2">
                        Reviewing: {reddit.title}
                    </div>
                </div>
            ))}
        </div> */}

        {(loaderVisible) &&
            (context.isMainPage ? (
                <div className='pb-6 mx-6  w-[75vw] max-w-[500px] mx-auto'>
                <div className="flex flex-col place-self-center items-center justify-center pt-5 max-w-[500px] mx-auto">
                <div className="p-2 my-1 text-gray-500">
                    {progressMessages.message}
                </div>
                </div>
                <Progress radius="xl" size="lg"  color="#0077b6" value={progressMessages.value} striped animate />
                {/* <div className="space-y-4">
                {redditRefs.slice(0, 4).map((reddit, index) => (
                <div
                    key={index}
                    style={{ animationDelay: `${index * 0.5}s` }}
                    className="animate-fade-in-down"
                >
                    <SourcePill src={reddit} />
                    <div className="text-center text-sm italic my-2">
                        Reviewing: {reddit.title}
                        </div>
                    </div>
                ))}
                </div> */}
                </div>



            ) : (
                <div className="flex flex-col place-self-center items-center justify-center pt-5 max-w-[400px] mx-auto">
                    {qapair.length >= (CHATS_BEFORE_RECS_START) && !isDone && <div className='font-gilroy mb-2.5'> Searching high and low 🧐 </div>}
                    <Loader variant="dots" />
                </div>
            ))}



            <div ref={bottomRef} className="max-w-[700px] mx-auto"/>

                {/* {!isDone && (
                <div className={styles.form}>
                    <ChatInput
                        query={query}
                        updateQuery={setQuery}
                        qapair={qapair}
                        topic={props.topic}
                        options={options}
                        open={props.feedbackOpened}
                        setOpen={props.setFeedbackOpened}
                        isMobile={props.isMobile}
                        sessionUUID={props.sessionUUID}
                        inputRef={inputRef}
                    />



                    <div className="max-w-[700px] mx-auto mt-[200px] mb-8">
                    <ChatInputBar
                        query={query}
                        updateQuery={setQuery}
                        qapair={qapair}
                        topic={props.topic}
                        handleSubmit={submitSearch}
                        options={options}
                        open={props.feedbackOpened}
                        setOpen={props.setFeedbackOpened}
                        isMobile={props.isMobile}
                        sessionUUID={props.sessionUUID}
                        inputRef={inputRef}
                    />
                    </div>
                </div>)} */}
            </div>
        </div>
    );
}
