import {
  Space,
  Paper,
  Group,
  Button,
  Title,
  Text,
  TextInput,
  ActionIcon,
  Container,
  Grid,
} from "@mantine/core";
import { TrendingUp } from "tabler-icons-react";
import React, { useState, useContext } from "react";
import { PageContext } from "../../contexts/pageContext";
import { AwesomeButton } from "react-awesome-button";
import { BetterButton } from "../utils/Buttons";
import { LocationInput } from "../LocationInput";
import { useEffect } from "react";
import "react-awesome-button/dist/styles.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { supabase_public } from "../../lib/supabaseClient";
import styles from "../styles/Search.module.css";

export function HeaderInput(props: any) {
  const [isDisabled, setIsDisabled] = useState(props.topicSelected || false);
  const [recentProducts, setRecentProducts] = useState([]);
  const [signedIn, setSignedIn] = useState(false);
  const context = useContext(PageContext);

  const API_URL = process.env.NEXT_PUBLIC_API_URL;

  //trigger update to isdisabled when topicselected is true
  useEffect(() => {
    if (props.topicSelected) {
      setIsDisabled(true);
    }
  }, []);

  useEffect(() => {
    getSetRecentProducts();
  }, []);

  function processTopic(topic) {
    //remove spaces at beginning and end
    topic = topic.trim();
    topic = topic.replace("||", "");
    topic = topic.replace(/\n\d+\. /, "");
    topic = topic.replace(/Option \d+: /, "");
    topic = topic.replace(/option \d+: /, "");
    topic = topic.replace(/^\d+\.(?=\s|$)/, "");
    topic = topic.replace(/\[/g, "");
    topic = topic.replace(/\]/g, "");
    return topic;
  }

  function getDBTopics() {
    fetch("/api/telemetry/topics")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setRecentProducts(data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  async function getSetRecentProducts() {
    if (context.isMainPage) {
      const { data, error } = await supabase_public.auth.getSession();
      const uuid = data?.session?.user.id || null;

      if (uuid) {
        setSignedIn(true);

        fetch(API_URL + `/topicGeneration`, {
          method: "POST",
          body: JSON.stringify({ uuid }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            //process topic for each topic
            data.topics.forEach((topic, index) => {
              data.topics[index] = processTopic(topic);
            });
            setRecentProducts(data.topics);
          })
          .catch((error) => {
            //if this api call fails / returns error call get getdbtopics too
            getDBTopics();
          });
      } else {
        //if signed in fetch API_URL + /topicGeneration and set that to recent products
        //fetch to /api/topics and set that to recent products
        getDBTopics();
      }
    } else {
      setRecentProducts(context.recentProducts);
    }
  }

  function handleChange(event: any) {
    props.updateTopic(event.target.value);
    //setIsDisabled(false);
    //props.setTopicSelected(false)
  }

  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      setIsDisabled(true);
      if (!props.topicSelected) {
        props.setTopicSelected(true);
      }
      //props.setTopicSelected(true)
      //props.updateTopic("")
      //return makeInput(event.target.value);
    }
  }

  function handleClick(event: any) {
    setIsDisabled(true);
    if (!props.topicSelected) {
      props.setTopicSelected(true);
    }
    //props.updateTopic("")
  }

  const paper_style = {
    padding: "5%",
    boxShadow: "0 4px 6px 0 hsla(210, 100%, 75%, 0.5)",
    borderRadius: "25px",
  };

  return (
    <>
      {!props.topicSelected && (
        <div
          className="px-2 mx-auto"
          style={{ maxWidth: 600, margin: "0 auto" }}
        >
          {context.linkSiteSubHeader ? (
            <div className="text-2xl p-4 md:text-4xl  text-shadow">
              <a href={context.website}>{context.subheader}</a>
            </div>
          ) : (
            <div className="text-2xl p-4 md:text-4xl  text-shadow ">
              {/* {context.subheader} */}
              <div className="p-4 text-center">What do you want to buy?</div>
            </div>
          )}

          {/* if context.id is giftem render locatioin input */}
          {context.id === "giftem" && (
            <>
              <br />
              <LocationInput
                updateLocation={props.updateLocation}
                setLocationSelected={props.setLocationSelected}
                location={props.location}
                setCoords={props.setCoords}
              />
            </>
          )}

          <div className="relative bg-gray-50 mx-4 md:mx-0">
            <input
              value={props.topic}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder={context.productPlaceholder}
              disabled={isDisabled}
              className={`w-full pl-10 p-4 rounded-xl focus:outline-none ring-blueoutline focus:ring-bluelink ring-2 shadow-md bg-gray-100 shadow-blueshadow   ${
                isDisabled ? "text-gray-500" : "text-gray-600"
              }`}
            />
            <div className="absolute inset-y-0 left-0 flex items-center p-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-5 w-5 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <div
              className="absolute inset-y-0 right-0 flex items-center p-3 cursor-pointer"
              onClick={handleClick}
            >
              <FaArrowRightLong size={25} color="grey" />
            </div>
          </div>
          <br />
          {signedIn ? (
            <>
              <Group position="center" className="pt-4">
                {/* <TrendingUp /> */}
                <div className="text-xl">Recommended For You:</div>
              </Group>
              <Group mt="md" position="center" className="pb-4">
                {recentProducts &&
                  recentProducts.map((product, index) => (
                    <BetterButton
                      noOutline={true}
                      fgcolor=" bg-blueoptionshover hover:bg-blueoptionsactive "
                      bgcolor="blueoptions"
                      onPress={() => {
                        if (product.sessionId) {
                          window.location.href = `${window.location.origin}/threads/${product.sessionId}`;
                        } else {
                          props.updateTopic(product);
                          setIsDisabled(true);
                          props.setTopicSelected(true);
                        }
                      }}
                      disabled={isDisabled}
                    >
                      <div
                        className={`inline-block ${
                          isDisabled ? "text-gray-700" : "text-gray-700"
                        }  text-base p-1`}
                      >
                        {product.topic
                          ? product.topic.length > 45
                            ? `${product.topic
                                .substring(0, 45)
                                .replace(/"/g, "")}...`
                            : product.topic.replace(/"/g, "")
                          : product.length > 45
                          ? `${product.substring(0, 45).replace(/"/g, "")}...`
                          : product.replace(/"/g, "")}
                      </div>
                    </BetterButton>
                  ))}
              </Group>
              <br />
            </>
          ) : (
            <>
              <Group position="center" className="pt-4">
                <TrendingUp />
                <div className="text-xl">Recent Products:</div>
              </Group>
              <Group mt="md" position="center" className="pb-4">
                {recentProducts &&
                  recentProducts.map((product, index) => (
                    <BetterButton
                      noOutline={true}
                      fgcolor=" bg-blueoptionshover hover:bg-blueoptionsactive"
                      bgcolor="blueoptions"
                      onPress={() => {
                        if (product.sessionId) {
                          window.location.href = `${window.location.origin}/threads/${product.sessionId}`;
                        } else {
                          props.updateTopic(product);
                          setIsDisabled(true);
                          props.setTopicSelected(true);
                        }
                      }}
                      disabled={isDisabled}
                    >
                      <div
                        className={`inline-block ${
                          isDisabled ? "text-gray-700" : "text-gray-700"
                        }  text-base p-1`}
                      >
                        {product.topic
                          ? product.topic.length > 45
                            ? `${product.topic
                                .substring(0, 45)
                                .replace(/"/g, "")}...`
                            : product.topic.replace(/"/g, "")
                          : product.length > 45
                          ? `${product.substring(0, 45).replace(/"/g, "")}...`
                          : product.replace(/"/g, "")}
                      </div>
                    </BetterButton>
                  ))}
              </Group>
            </>
          )}
        </div>
      )}
    </>
  );
}
