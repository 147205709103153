import React, { useContext, useState } from 'react';
import { PageContext } from '../../contexts/pageContext';
export function BetterButton(props: any) {


    const padding = props.paddingOverride ? props.paddingOverride : 'px-5';
    const context = useContext(PageContext)
    const [isClicked, setIsClicked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        if (!props.disabled){
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        if (!props.disabled){
            setIsHovered(false);
        }
    };

    const style: React.CSSProperties =
    props.disabled ? {
        backgroundColor: '#afafaf',
        cursor: 'default',
        pointerEvents: 'none'
    } : isClicked? {
    //   transform: "translate3d(0px, 4px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    //   transformStyle: "preserve-3d",
    //   transition: "transform 0.1s"
    } : isHovered ? {
    //   transform: "translate3d(0px, 2px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    //   transformStyle: "preserve-3d",
    //   transition: "transform 0.1s"
        cursor: 'pointer'

    } : {};

    function handleClick(answer) {
        if (!props.disabled){
        // posthog.capture('feedback clicked' , { 'feedback': answer,  storeId: context.storeId })
        // alert("Thank you for your feedback!")
            setIsClicked(true)
            props.onPress()
            setIsClicked(false)
        }
    }

    return (

            <div className={`relative inline-flex`}>
                <div
                    onClick={handleClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}

                    className={`relative flex items-center justify-center rounded-xl w-full h-full bg-${props.fgcolor}  ${props.noOutline ? '' : 'border-2 border-blueoutline'  } ${padding} py-1 text-base sm:text-base md:text-lg font-medium ${props.disabled ? 'shadow-inner border-zinc-400 text-black' : isClicked ? 'shadow-inner shadow-blueshadow shadow-md' : isHovered ? 'transition duration-200 ease-in-out transform hover:scale-105' : 'shadow-blueshadow'} transform transition-transform ${props.fontcolor} ${props.font}`}
                    style={style}>
                {props.children}
                </div>
                {/* <div className={`absolute top-0.5 z-0 w-full rounded-xl h-full bg-${props.bgcolor}`}></div> */}
            </div>
    )
}