import { useState, useEffect, useRef } from 'react';
import { createStyles, Header, Container, Group, Burger, Title, Text, Paper, Transition, Image } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { HeaderInput } from './headers/HeaderInput';
import { PageContext } from '../contexts/pageContext';
import { useContext } from 'react';
import { AwesomeButton } from "react-awesome-button";
import TopBanner from './TopBanner';
import { supabase_public, } from '../lib/supabaseClient';
import AuthPrompt from './AuthPrompt';
import { FaHistory, FaUser} from 'react-icons/fa';
import { MdFeedback } from 'react-icons/md';
import { MdOutlineFeedback, MdHistory, MdPerson } from "react-icons/md";
import { VscFeedback } from 'react-icons/vsc';
import UserConversations from './UserConversations';
import AddToHomeScreenBanner from './AddToHomeScreenBanner';


const HEADER_HEIGHT = 'auto';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    height: 'auto',
    borderBottom: `0px`
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },


}));


// style={{color:inherit ; text-decoration:none}}
export function MobileWrapper({userid, sessionUUID, children, loggedInUsername, showAuthPrompt, setShowAuthPrompt, topicSelected, feedbackOpened, setFeedbackOpened}) {

  const context = useContext(PageContext);
  const { classes, cx } = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const [session, setSession] = useState(null)
  const [email, setEmail] = useState(null)
  // const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [showHistoryDropdown, setShowHistoryDropdown] = useState(false);

  const historyDropdownRef = useRef(null);
  const userDropdownRef = useRef(null);
  const [status, setStatus] = useState(null)
  const [showBanner, setShowBanner] = useState(true);
  const handleCloseBanner = () => {
      setShowBanner(false);
  };



  useEffect(() => {
    async function fetchAllData() {
        const { data, error } = await supabase_public.auth.getSession();

        if (error) {
            console.log("error retrieving session");
            // setShowAuthPrompt(true);
            return;
        }


        if (data.session && data.session.expires_at * 1000 < Date.now()) {
            console.log(data.session.expires_at * 1000, Date.now())
            supabase_public.auth.signOut();
            setSession(null);
            console.log("session expired")
            // setShowAuthPrompt(true);
            return;
        }

        setSession(data);
        const email = data?.session?.user?.email;
        if (email) {
            // Set the email
            setEmail(email);
        }
        // if (!data || data.session == null) {
        //     setShowAuthPrompt(true);
        // }
    }
    fetchAllData();
}, [userid]); // Empty dependency array ensures this runs once on mount

  const handleSignOut = async () => {
    console.log("signing out")
    await supabase_public.auth.signOut()
    window.location.reload(); // Reload the page on redirect
  }

  const handleProfileClick = async () => {
      window.location.href = window.location.origin +  `/profile/${loggedInUsername}`;
  }

  useEffect(() => {
    const handleUserClickOutside = (event) => {
        if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
            setShowUserDropdown (false);
        }
    }

    document.addEventListener("mousedown", handleUserClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleUserClickOutside);
    };
}, []);

useEffect(() => {
  const handleHistoryClickOutside = (event) => {
      if (historyDropdownRef.current && !historyDropdownRef.current.contains(event.target)) {
          setShowHistoryDropdown(false);
      }
  }

  document.addEventListener("mousedown", handleHistoryClickOutside);
  return () => {
      document.removeEventListener("mousedown", handleHistoryClickOutside);
  };
}, []);

  return (
    <>
    <div style={{ position: 'sticky', top: 0, zIndex: 999 }}>
      {(context.id != "stonetooling") && (
      <>
      {/* <TopBanner /> */}
      <div className={`flex items-center justify-between px-4 bg-cardBg border-subtleBorder`}>
            <div className="flex items-center">
                <a href="/" className="flex items-center">
                    {/* <img src="/logo-circle.png" className="h-12 sm:h-12 mr-3 " alt="Claros Logo" /> */}
                    <span className="self-center text-xl font-semibold whitespace-nowrap  text-bluelogo ">Claros</span>
                </a>
            </div>

            <div className="flex justify-center items-center py-2">
                {!session || session.session == null && (
                  <>
                  <button className="py-2 px-4  text-bgwhite text-sm rounded-md font-sans" onClick={() => setFeedbackOpened(true)}>
                     <MdOutlineFeedback size={24} className='text-bluelogo'/>
                 </button>
                    <button className="py-2 px-4 bg-bluelogo text-bgwhite text-sm rounded-md font-sans" onClick={() => setShowAuthPrompt(true)}>
                        Sign in
                    </button>
                  </>
                )}

                {showAuthPrompt && <AuthPrompt sessionId={sessionUUID} showCloseButton={true} setShowAuthPrompt={setShowAuthPrompt} topicSelected={topicSelected}/>}


                {session && session.session && (
                  <div className='justify-center items-baseline'>
                    <div className="flex justify-center items-baseline">
                      <button className="py-2  mx-2 rounded-md font-sans" onClick={() => setFeedbackOpened(true)}>
                          <MdOutlineFeedback size={24} className='text-2xl text-bluelogo'/>
                      </button>
                      <button className="py-2 rounded-md mx-2" onClick={() => setShowHistoryDropdown(!showHistoryDropdown)}>
                        <MdHistory className=' text-2xl text-bluelogo' />
                      </button>
                        <div className="absolute right-0 mt-14 bg-gray-100 rounded-xl border-2  border-bluelogo" hidden={!showHistoryDropdown} ref={historyDropdownRef}>
                          <div className="overflow-scroll max-h-[45vh]" style={{ overflowY: 'scroll' }}>
                            <UserConversations setSelectedConversation={selectedConversation} uid={userid} />
                          </div>
                        </div>
                      <button className="py-2 rounded-md mx-2" onClick={() => setShowUserDropdown(!showUserDropdown)}>
                        <MdPerson className=' text-2xl text-bluelogo' />
                      </button>

                      {showUserDropdown && (
                        <div className="absolute right-0 mt-14 bg-gray-100 rounded-xl border-2  border-bluelogo" ref={userDropdownRef}>
                          <div className="flex flex-col items-center p-4">
                            <p className="mb-2">{session?.session?.user?.email}</p>
                            <button className="py-2 text-bluelogo rounded-md justify-center mx-auto" onClick={handleSignOut}>
                              Sign Out
                            </button>
                            <button className="py-2 text-bluelogo rounded-md justify-center mx-auto" onClick={ handleProfileClick}>
                              See Profile
                            </button>

                          </div>
                        </div>
                      )}
                  </div>
                  </div>
                )}
            </div>
      </div>
      </>
      )}
    </div>
    <div className="w-screen overflow-x-hidden min-w-screen  bg-bgwhite h-screen">
    {/* { context.isMainPage && showBanner && <AddToHomeScreenBanner onClose={handleCloseBanner} /> } */}

    {/* <div className="flex-1 flex-grow p-4"> */}
        {children}
    {/* </div> */}
    </div>
    </>
  );
}
