import { useState, useEffect, useRef } from 'react';
import { ProductCard }from './ProductCard';
import { FaArrowRightLong } from 'react-icons/fa6';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { extractHostname } from './utils';


function filterDomains(items, domains) {
  return items.filter((item) => {
    const domain = extractHostname (item.detailPageURL);
    return domains.includes(domain);
  })
}


function convertPrice(price) {
  //if number return number
  if (typeof price === 'number') {
    return price;
  }
  //if string remove $ and ,
  return parseFloat(price.replace(/[$,]/g, ''));
}


function sortLowToHigh(items) {
    return items.sort((a, b) => convertPrice(a.price) - convertPrice(b.price));
}

function sortHighToLow(items) {
    return items.sort((a, b) => convertPrice(b.price) - convertPrice(a.price));
}

function sortRatingsLowToHigh(items) {
    return items.sort((a, b) => a.rating - b.rating);
}

function sortRatingsHighToLow(items) {
    return items.sort((a, b) => b.rating - a.rating);
}


function ProductCarousel({ products, sort, isMobile, handleProductSpecificSearch, domains, last}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isUserScrolling, setIsUserScrolling] = useState(true);
  const [userScrollPosition, setUserScrollPosition] = useState(0);
  const [sortedProducts, setSortedProducts] = useState([]);
  const containerRef = useRef(null);




  const handleScrollRight = () => {
    console.log(canScrollRight)
    const container = containerRef.current;
    const cardWidth = container.scrollWidth / products.length;
    const maxScrollPosition = container.scrollWidth - container.clientWidth;
    const newScrollPosition = userScrollPosition + container.clientWidth;

    setIsUserScrolling(false)
    setScrollPosition(newScrollPosition);
    setCanScrollLeft(newScrollPosition > 0);
    setCanScrollRight(newScrollPosition < maxScrollPosition || newScrollPosition === maxScrollPosition);
  };

  const handleScrollLeft = () => {
    console.log(canScrollLeft)
    const container = containerRef.current;
    if (container) {
      const newScrollPosition = userScrollPosition - container.clientWidth;
      setIsUserScrolling(false)
      setScrollPosition(newScrollPosition);
      const cardWidth = container.scrollWidth / products.length;
      setCanScrollRight(newScrollPosition < container.scrollWidth - container.clientWidth);
      setCanScrollLeft(newScrollPosition > 0 || newScrollPosition === 0);
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
        const newScrollPosition = container.scrollLeft;
        const maxScrollPosition = container.scrollWidth - container.clientWidth;

        setUserScrollPosition(newScrollPosition);
        setCanScrollLeft(newScrollPosition > 0);
        setCanScrollRight(newScrollPosition < maxScrollPosition);
    };
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

  }, [products]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({ left: scrollPosition, behavior: 'smooth' });
    }
  }, [scrollPosition]);

   // Function to calculate the average price
   const calculateAveragePrice = (products) => {
    const total = products.reduce((acc, product) => acc + product.price, 0);
    return total / products.length;
  };

  useEffect(() => {
    let filtered_products = products
    if (domains.length > 0 && !domains.includes("all") && last) {
      filtered_products = filterDomains(products, domains)
    }
    const _sortedProducts = sortProducts(filtered_products)
      // .filter(product => product.price >= thresholdPrice);

    setSortedProducts(_sortedProducts);
  }, [sort, products]);

  const sortProducts = (items) => {
    switch (sort) {
      case 'priceLowToHigh':
        return sortLowToHigh(items);
      case 'priceHighToLow':
        return sortHighToLow(items);
      case 'ratingsLowToHigh':
        return sortRatingsLowToHigh(items);
      case 'ratingsHighToLow':
        return sortRatingsHighToLow(items);
      case 'noSort':
        return items;
      default:
        return items;
    }
  };

  return (
    <div className="relative  max-w-[100vw]">
      { sortedProducts.length > 0  && (
      <>
      <div className={`flex overflow-x-scroll pt-6 transition-transform duration-500 ease-in-out mx-auto ${isMobile ? 'min-w-[100vw]' : 'max-w-[80vw]'}  `} ref={containerRef}>
        {sortedProducts.map((product, index) => (
          <ProductCard
            key={product.id}
            product={product}
            image={product.imageUrl}
            price={product.price}
            title={product.title}
            link={product.detailPageURL}
            rating={product.rating}
            sku={product.sku}
            inlineProduct={true}
            bestSeller={product.best_seller}
            amazonsChoice={product.is_amazons_choice}
            reviews_count={product.reviews_count}
            source={product.source}
            index={index}
            handleProductSpecificSearch={handleProductSpecificSearch}
            aiSummary={product.aiSummary}
          />
        ))}
      </div>
      {!isMobile && (
        <>
          <div className="flex max-w-[700px] mx-auto" >
              <div
                className={`cursor-pointer p-4 bg-bgwhite border-blueoutline border-none rounded-xl width-full  mx-auto w-[30%] max-w-[100px] ${!canScrollLeft ? ' pointer-events-none ' : ''}`}
                onClick={canScrollLeft ? handleScrollLeft : undefined}
              >
                <FaArrowLeftLong className={`text-4xl ${canScrollLeft ? 'text-blueoutline' : 'text-gray-300'} mx-auto mr-3`} />
                <div className={`text-center ${canScrollLeft ? 'text-blueoutline' : 'text-gray-300'} justify-center align-center`} >
                Prev

                </div>
              </div>
              <div
                className={`cursor-pointer p-4 bg-bgwhite  rounded-xl mx-auto w-[30%] max-w-[100px] ${!canScrollRight ? 'pointer-events-none ' : ''}`}

                onClick={canScrollRight ? handleScrollRight : undefined}
              >
                <FaArrowRightLong className={`text-4xl ${canScrollRight ? 'text-blueoutline' : 'text-gray-300'} mx-auto`} />
                <div className={`text-center ${canScrollRight ? 'text-blueoutline' : 'text-gray-300'}`} >
                Next

                </div>
              </div>
          </div>
        </>
        )}
      </>
      )}
    </div>

  );
}

export default ProductCarousel;
