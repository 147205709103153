import React, { useContext, useRef, useEffect } from 'react';
import { Send, Refresh, Share, Clipboard } from 'tabler-icons-react';
import { Space, Group,  ActionIcon, } from '@mantine/core';
import { PageContext } from '../../contexts/pageContext'
import { FaRobot, FaForward } from "react-icons/fa";
import parseMarkdown from '../utils/MarkdowntoHTML'

export function ChatInputBar(props) {
    const inputRef = useRef(null);

    // Placeholder for PageContext - replace with actual context in your application
    const context = useContext(PageContext) // Example color, replace with actual value

    function handleChange(event) {
        const inputOptions = event.target.value;
        props.updateQuery(inputOptions);
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            props.handleSubmit(props.query, props.qapair, props.topic);
            props.updateQuery("");
        }
    }

    function handleClick() {
        props.handleSubmit(props.query, props.qapair, props.topic);
        props.updateQuery("");
    }

    return (
        <div className='float-left bg-white border border-gray-300 flex py-1  rounded-xl shadow px-4  w-full mb-4 md:max-w-md mx-auto '>
            <input
                type="text"
                ref={inputRef}
                value={props.query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Other"
                className="rounded-xl flex-grow bg-transparent text-gray-700 placeholder-gray-400 focus:outline-none "
            />
             <div className="justify-end flex">
              <ActionIcon variant="transparent" onClick={handleClick} className='mx-1'>
                  <Send
                      style={{ color: context.buttonColor }}
                      size={40}
                      color={context.buttonColor}
                  />
              </ActionIcon>
              </div>
        </div>
    );
}




const QuestionCard = (props: any) => {

  const [message, setMessage] = React.useState("");

  useEffect(() => {
    const [message, productDescriptionMap] = parseMarkdown(props.message, "sm")
    setMessage(message)
    props.setProductDescriptionMap(productDescriptionMap)
  }, [props.message])


  function handleOptionClick(option) {
    option = option.trim();
    if (props.query.includes(option)) {
        console.log("found option toggle off");
        let newQuery = props.query.replace(option, '').trim();
        newQuery = newQuery.replace(/,+/g, ',').trim();
        newQuery = newQuery.replace(/,(\s,)+/g, ',').trim();
        if (newQuery.startsWith(',')) {
            newQuery = newQuery.slice(1);
        }
        props.updateQuery(newQuery);
    } else {
        let newQuery = `${props.query}${option}, `;
        props.updateQuery(newQuery);
        }
    }


    // <img src="/logo-circle.png" className='h-6 w-6 inline text-center' />
    return (
      <div className="mx-2 md:mx-12 my-4 max-w-md bg-white rounded-lg overflow-hidden md:max-w-xl ">
        <div className="md:flex">
          <div className="w-full p-4">
            <div className="mb-4">
              <h1 className="text-lg font-bold text-gray-700 mb-4"> <img src="/logo-circle.png" className='h-6 w-6 inline text-center mb-[2px] mr-[2px]' />  Claros </h1>
                {props.isProductsMsg ?  (
                <div className="text-gray-600 " dangerouslySetInnerHTML={{ __html: message}} /> ):
                (
                <p className="text-gray-600 ">{props.message} </p> )}
            </div>
            <div className="mb-4">

              <div className="flex flex-wrap justify-start gap-2">
                  {props.options && props.options.map((option, idx) => (
                      <button
                          key={idx}
                          onClick={() => handleOptionClick(option)}
                          className="bg-blue-200 hover:bg-blue-300 text-gray-700 py-2 px-4 rounded-full text-sm font-medium focus:outline-none "
                      >
                          {option}
                      </button>
                  ))}
              </div>


            </div>
            <ChatInputBar
                 query={props.query}
                 updateQuery={props.updateQuery}
                 qapair={props.qapair}
                 topic={props.topic}
                 handleSubmit={props.handleSubmit}
                 options={props.options}
                 isMobile={props.isMobile}
                 sessionUUID={props.sessionUUID}
                 inputRef={props.inputRef}
            />

            { !props.isProductsMsg  && (

            <div className='block float-right max-w-[200px] text-base  ml-4 md:ml-12'>
            <button onClick={props.handleSkipToRecs} className="hover:bg-gray-100 rounded-xl cursor-point active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium hover:text-bluelogo p-2 text-bluelink items-center">
                <div className='px-2 cursor-pointer'><FaForward size={12}/></div>
                <div className='pr-2 cursor-pointer '> Skip questions</div>
            </button>
            </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  export default QuestionCard;