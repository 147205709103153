export async function geocodeCity(cityName) {
  var url = 'https://nominatim.openstreetmap.org/search?q=' + encodeURIComponent(cityName) + '&format=json';

  // Send a GET request to the Nominatim API
    let data = await fetch(url)
        .catch(error => {
            console.log('An error occurred during geocoding:', error);
        });
    //if data is of type Response, we need to call the json() method to get the actual data
    if (data instanceof Response) {
        data = await data.json();
    }
    return data;
}
