export const EMOJI_DICT = {
    'book': '📚',
    'car': '🚗',
    'shoe': '👟',
    'ball': '⚽',
    'bottle': '🍼',
    'bike': '🚴',
    'cook': '🍳',
    'write': '✍️',
    'garden': '🌱',
    'fish': '🎣',
    'paint': '🎨',
    'shop': '🛍️',
    'music': '🎵',
    'dance': '💃',
    'hike': '🥾',
    'swim': '🏊',
    'travel': '🌍',
    'apple': '🍏',
    'banana': '🍌',

    'football': '🏈',
    'guitar': '🎸',
    'house': '🏠',
    'ice cream': '🍦',
    'jeep': '🚙',
    'kite': '🪁',
    'lemon': '🍋',
    'mango': '🥭',
    'notebook': '📔',
    'orange': '🍊',
    'pizza': '🍕',
    'quilt': '🛏',
    'rainbow': '🌈',
    'soccer ball': '⚽',
    'tomato': '🍅',
    'umbrella': '☂️',
    'violin': '🎻',
    'watermelon': '🍉',
    'xylophone': '🎹',
    'yoga': '🧘‍♂️',
    'aeroplane': '✈️',
    'basketball': '🏀',
    'camera': '📷',

    'golf': '🏌️',
    'club': '🏌️',
    'hammock': '🛌',
    'ice skates': '⛸',
    'juice': '🥤',
    'keychain': '🔑',
    'laptop': '💻',
    'moon': '🌙',
    'nail polish': '💅',
    'pencil': '✏️',
    'quill pen': '🖋',
    'roller coaster': '🎢',
    'sunglasses': '🕶️',
    'telescope': '🔭',
    'volleyball': '🏐',
    'watch': '⌚',
    'yacht': '⛵',
    'zipper': '🤐',
    'ice': '🧊',
    'snowboard': '🏂',
    'skis': '⛷',
    'computer': '💻',
    'television': '📺',
    'headphones': '🎧',
    'microphone': '🎤',
    'smartphone': '📱',
    'tablet': '📲',
    'printer': '🖨️',

    'fridge': '🧊',
    'oven': '🍳',
    'washing machine': '🌀',
    'drone': '🚁',

    'motorcycle': '🏍️',
    'scooter': '🛴',
    'helmet': '🪖',
    'backpack': '🎒',
    'sneakers': '👟',
    'boots': '🥾',
    'hat': '👒',

    'jewelry': '💍',
    'purse': '👜',
    'suitcase': '🧳',
    'makeup': '💄',
    'perfume': '🧴',
    'toothbrush': '🪥',
    'razor': '🪒',
    'furniture': '🪑',
    'lamp': '💡',
    'fan': '🌀',
    'tools': '🔧',
    'paintbrush': '🖌️',
    'tape': '📏',
    'battery': '🔋',
    'cd': '💿',
    'video game': '🎮',
    'gaming': '🎮',
    'game': '🎲',
    'board': '🎲',
    'cards': '🃏',
    'glasses': '👓',
    'binoculars': '🔭',
    'tent': '⛺',
    'fishing rod': '🎣',
    'bicycle': '🚲',
    'skateboard': '🛹',
    'treadmill': '🏃‍♂️',
    'weight': '🏋️‍♂️',
    'gloves': '🧤',
    'scarf': '🧣',
    't-shirt': '👕',
    'dress': '👗',
    'jeans': '👖',
    'socks': '🧦',
    'mug': '☕',
    'wine glass': '🍷',
    'beer': '🍺',
    'plate': '🍽️',
    'cutlery': '🍴',
    'toys': '🧸',
    'doll': '🪆',
    'robot': '🤖',
    'balloon': '🎈',
    'candle': '🕯️',
    'bookshelf': '📚',
    'calendar': '📅',
    'clock': '🕰️',
    'plant': '🌱',
    'flower': '💐',
    'pot': '🍯',
    'vacuum': '🧹',
    'broom': '🧹',
   'blender': '🍹',
   'chocolate': '🍫',
   'candy': '🍬',
   'gum': '🍬',
   'coffee': '☕',
   'tea': '🍵',
   'spices': '🧂',
   'spaghetti': '🍝',
   'rice': '🍚',
   'bread': '🍞',
   'cheese': '🧀',
   'wine': '🍷🍾',
   'champagne': '🍾',
   'baby': '👶',
   'clothes': '👕',
   'sweater': '🧥',
   'jacket': '🧥',
   'tie': '👔',
   'briefcase': '💼',

   'screwdriver': '🔩🔧',
   'nails': '🔩',
   'hammer': '🔨',
   'saw': '🪚',
   'tape measure': '📏',
   'light bulb': '💡',
   'flashlight': '🔦',
   'couch': '🛋️',
   'bed': '🛏️',
   'pillow': '🛏️🌛',
   'desk': '🪑🖥️',
   'rug': '🧶',
   'curtains': '🪟🧣',
   'diapers': '👶🚽',
   'baby bottle': '🍼',
   'pacifier': '👶🍭',
   'shampoo': '🚿🧴',
   'conditioner': '🚿🧴',
   'soap': '🧼',
   'toilet paper': '🧻',
   'towel': '🚿🔳',
   'mirror': '🪞',
   'comb': '🪒🪥',
   'hair dryer': '💇‍♀️🌬️',
   'hair straightener': '💇‍♀️🔥',
   'makeup brushes': '💄🖌️',
   'safety pins': '🧷',
   'sewing machine': '🪡🧵',
   'thread': '🧵',
   'needle': '🪡',
   'buttons': '🔘',
   'scissors': '✂️',
   'gift wrap': '🎁',

   'envelopes': '✉️',
   'stamps': '📬🔖',
   'pens': '🖊️',
   'markers': '🖍️',
   'eraser': '🩹🖊️',
   'stapler': '📎',
   'paper clips': '📎',
   'calculator': '🧮',
   'ruler': '📏',
   'globe': '🌍',
   'map': '🗺️',
   'compass': '🧭',

   'greeting cards': '💌',
   'party decorations': '🎉🎈',
   'candles': '🕯️',
   'birthday cake': '🎂',
   'soda': '🥤',
   'milk': '🥛',
   'cereal': '🥣',
   'eggs': '🥚',
   'fruits': '🍎🍌🍇',
   'vegetables': '🥦🥕🍅',
   'meat': '🥩',

   'chicken': '🍗',
   'ice cube tray': '🧊🥤',

   'dishes': '🍽️',
   'silverware': '🍴',
   'vase': '💐🏺',

   'file cabinet': '🗄️',
   'clipboard': '📋',

   'handbag': '👜',
   'necklace': '💍📿',
   'heels': '👠',

   'pet food': '🍲',
   'pet bed': '🛏️🐾',
   'pet toys': '🧸🐾',
   'leash': '🐕🪢',
   'charger': '🔌🔋',
   'speakers': '🔈',
   'remote': '📺🔘',
   'cushion': '🛋️🔳',
   'painting': '🖼️',
   'piano': '🎹',
   'drums': '🥁',
   'flute': '🎶🪶',
   'campfire': '🔥',
   'lantern': '🔦',
   'tennis racket': '🎾',
   'golf club': '🏌️',
   'baseball bat': '⚾🏏',
   'toy car': '🚗🧸',
   'puzzle': '🧩',
   'board game': '🎲',
   'glass': '🍷',
   'lipstick': '💄',
   'makeup kit': '💄🎨',
   'chandelier': '💡🎇',
   'bedspread': '🛏️🧶',
   'desk lamp': '💡📚',
   'alarm clock': '⏰',
   'ceiling fan': '🌀🏠',
   'wall clock': '🕰️🏠',
   'blow dryer': '💨💇',
   'microwave': '🍛⏲️',
   'dishwasher': '🍽️💧',
   'sofa': '🛋️',
   'stool': '🪑',
   'photo frame': '🖼️',
   'wall art': '🎨🏠',
   'coffee maker': '☕🔌',
   'frying pan': '🍳',
   'grill': '🍖🔥',
   'juicer': '🍊💦',
   'mixer': '🥣🌀',
   'oven mitts': '🍳🤚',
   'pots': '🍲',
   'kettle': '🍵🔥',
   'cutting board': '🔪🔲',
   'thermometer': '🌡️',
   'first aid kit': '🩺🧰',
   'fire extinguisher': '🔥❌',
   'lawn mower': '🏡✂️',
   'toolbox': '🧰',
   'drill': '🔩🔌',
   'ladder': '🪜',
   'mop': '💧🧹',
   'bucket': '💧🪣',
   'trash can': '🗑️',
   'recycling bin': '♻️🗑️',
   'camera tripod': '📷🔭',
   'phone charger': '🔌📱',
   'tablet stand': '📲🪑',
   'wireless mouse': '🖱️🔋',
   'keyboard': '⌨️',
   'earbuds': '🎧',
   'speaker': '🔈',
   'router': '🌐🔌',
   'power bank': '🔋📱',
   'memory card': '📸💾',
   'flash drive': '💾🔌',
   'sd card': '📸💽',
   'bracelet': '💎🔗',
   'belt': '👖🔗',
   'wallet': '💵🔳',
   'baseball cap': '🧢',
   'hairband': '🎀',
   'anklet': '🦶💎',
   'necktie': '👔',
   'high heels': '👠',
   'sandals': '🩴',
   'slippers': '🥿',
   'swim trunks': '🩲🏊',
   'bikini': '👙',
   'bra': '🩱',
   'underwear': '🩲',
   'pajamas': '🛌🩳',
   'blouse': '👚',
   'skirt': '🩳',
   'coat': '🧥',
   'hoodie': '👕🧣',
   'jumpsuit': '🩳👕',
   'gown': '👗🌌',
   'shorts': '🩳',
   'tights': '🩳🦵',
   'leggings': '🩳🦵',
   'swimsuit': '👙🏊',
   'sports bra': '🩱🏋️',
   'tank top': '👕☀️',
   'raincoat': '🧥🌧️',
   'windbreaker': '🧥🌬️',
   'vest': '👕🔳',
   'beanie': '👒❄️',
   'beret': '👒🎨',
   'fedora': '👒🕵️',
   'turban': '👳👒',
   'headscarf': '👳‍♀️🧣',
   'headband': '🎀👱‍♀️',
   'bandana': '🧣🤠',
   'crown': '👑',
   'tiara': '👑👸',
   'mask': '🎭',
   'monocle': '🧐',
   'safety goggles': '👓🔨',
   'earrings': '👂💎',
   'pendant': '💎🔗',
   'ring': '💍',
   'brooch': '👚💎',
   'nose ring': '👃💍',
   'wristwatch': '⌚',
   'pocket watch': '⌚👖',
   'smartwatch': '⌚📱',
   'cufflinks': '👔💎',
   'tie clip': '👔🔗',
   'air purifier': '🌀🧼',
   'humidifier': '💧🌀',
   'dehumidifier': '🌀❌💧',
   'space heater': '🔥🔌',

   'air conditioner': '❄️🌀',
   'refrigerator': '🧊🍏',
   'deep freezer': '🧊❄️',
   'dish rack': '🍽️🔳',
   'can opener': '🥫🔪',
   'wine opener': '🍷🔩',
   'measuring cups': '🍵📏',
   'kitchen scale': '🍏⚖️',
   'mixing bowls': '🥣🌀',
   'whisk': '🥚🌀',
   'rolling pin': '🍞🪄',
   'oven gloves': '🍳🧤',
   'knives set': '🔪🔪',
   'spatula': '🍳🔀',
   'coaster': '🍺🔳',

   'wine rack': '🍷🔳',
   'placemat': '🍽️🔳',
   'thermos': '☕🔒',
   'salt shaker': '🧂',
   'pepper mill': '🧂🔄',
   'picnic basket': '🍞🧺',
   'office chair': '🪑💼',
   'pen holder': '🖊️🔳',
   'file folder': '📁',
   'staples': '📎🔗',
   'hole punch': '📄🕳️',
   'laminator': '📄✨',
   'shredder': '📄❌',
   'whiteboard': '⬜🖊️',
   'projector': '🎥🖼️',
   'stationery': '✏️📎',
   'batteries': '🔋',
   'torch': '🔦',
   'extension cord': '🔌🔗',
   'wireless charger': '📱⚡',
   'external hard drive': '💾🔋',

   'modem': '📡🔌',
   'ear plugs': '👂❌',
   'sleeping mask': '👀❌🌙',
   'travel pillow': '🧳🛌',
   'luggage tag': '🧳🏷️',
   'passport cover': '🛂📘',
   'power adapter': '🔌🌐',
   'travel guide': '🧳📚',
   'toiletry bag': '🛁🧳',
   'sunscreen': '☀️🧴',
   'insect repellent': '🦟❌',
   'water bottle': '💧🍼',
   'hiking boots': '🥾⛰️',
   'camping stove': '🔥🥘',
   'sleeping bag': '🛌⛺',
   'camping chair': '🪑⛺',
   'snorkel': '🌊👃',
   'flippers': '🌊🦶',
   'life jacket': '🌊🦺',
   'surfboard': '🌊🏄',
   'kayak': '🌊🚣',
   'paddle': '🌊🪜',
   'tent pegs': '⛺🔩',

   'hiking stick': '🚶🪄',


}

export function findEmoji(query) {
    // Normalize the full query string
    const normalizedQuery = query.toLowerCase().trim();
    // If the full query matches
    if (EMOJI_DICT[normalizedQuery]) {
        return EMOJI_DICT[normalizedQuery];
    }

    // Split query into words and try to find individual word matches
    const words = normalizedQuery.split(/\s+/);
    let resultEmojis = '';

    for (let word of words) {
        const normalizedWord = word.toLowerCase();
        if (EMOJI_DICT[normalizedWord]) {
            resultEmojis += EMOJI_DICT[normalizedWord];
        }

          // If the word ends with "s", try without the "s"
        else if (normalizedWord.endsWith('s') && EMOJI_DICT[normalizedWord.slice(0, -1)]) {
            resultEmojis += EMOJI_DICT[normalizedWord.slice(0, -1)];
        }
    }
    // If we found any word matches, return the concatenated result
    if (resultEmojis.length > 0) {
        return resultEmojis;
    }

    // Otherwise, return the search emoji
    return '🔎';
}