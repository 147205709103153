import {Space, Paper, Group, Button, Title, Text, TextInput, ActionIcon, Container, Grid, Autocomplete } from '@mantine/core';
import { TrendingUp} from 'tabler-icons-react'
import React, { useState, useContext } from 'react';
import { PageContext } from '../contexts/pageContext';
import { AwesomeButton } from "react-awesome-button";
import { geocodeCity } from './utils/Geocode';
import 'react-awesome-button/dist/styles.css'; 

export function LocationInput(props: any) {

    const [isDisabled, setIsDisabled] = useState(false);
    const [locationOptions, setLocationOptions] = useState([]);
    const [name, setName] = useState('');
    const context = useContext(PageContext);

    async function fetchLocationOptions(newValue) {
        const data = await geocodeCity(newValue);
        //set location options to be the display_names of everything
        if (data instanceof Array) {
            setLocationOptions(data.map((item) => item.display_name));
        }
        return locationOptions; 
    }

    async function handleChange(newValue) {
        props.updateLocation(newValue)
        console.log(props.location)
        if (props.location.length > 2) {
            await fetchLocationOptions(newValue);
        }
        console.log(locationOptions);
        props.setLocationSelected(true)
        //setIsDisabled(false);
        //props.setLocationSelected(false)
    }

    function handleKeyDown(event: any) {
        if (event.key === 'Enter') {
            setIsDisabled(true);
            props.setLocationSelected(true)
            //props.updateTopic("")
            //return makeInput(event.target.value);

        }
    }

    function handleClick(event: any) {
        setIsDisabled(true);
        props.setLocationSelected(true)
        //props.updateTopic("")
    }


    return (
       <>
        <div>
            <Grid>

            <Grid.Col md={5} lg={4}>
            <div className="text-xl sm:text-2xl font-gilroy text-gray-500 text-shadow  ">
                Location:
            </div>
            </Grid.Col>

            {/*<TextInput
                styles={{rightSection: { pointerEvents: 'none' } }}
                value={props.topic}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                disabled={isDisabled}
    />*/}
            <Grid.Col xs={'auto'} md={'auto'} lg={'auto'}>
            <Autocomplete
                value={props.location}
                onChange={handleChange}
                data={locationOptions}
            />

            </Grid.Col>

        </Grid>
        <br />
    </div>
    </>
    )
}
