import { useState, useEffect } from 'react';
import { extractHostname } from './utils';
import { Modal } from '@mantine/core';

function extractUniqueDomains(products: any) {
    const domains = new Set();
    products.forEach((product: any) => {
        const domain = extractHostname(product.detailPageURL);
        domains.add(domain);
    });
    return Array.from(domains);
}

export function DomainFilter(props: any) {
    const [uniqueDomains, setUniqueDomains] = useState([]);

    useEffect(() => {
        const domains = extractUniqueDomains(props.products);
        setUniqueDomains(domains);
    }, [props.products]);

    const handleDomainClick = (domain) => {
        const newDomainFilter = props.domainFilter.includes(domain)
            ? props.domainFilter.filter(d => d !== domain)
            : [...props.domainFilter, domain];
        props.setDomainFilter(newDomainFilter);
    };
    if (uniqueDomains.length > 1) {
        return (
            <>

  <div className="mt-4 mx-2 text-sm font-medium text-gray-500">
        Domains:
        <button
            onClick={() => props.setIsDomainModalOpen(true)}
            className=" ml-1 my-1 py-2 px-2 text-xs  bg-white text-black  rounded-md  focus:outline-none ring-1 ring-gray-300"
        >
            { props.domainFilter.length === 0 ?
            "All domains" : props.domainFilter[0]
            }

        </button>
 </div>


  <Modal
    opened={props.isDomainModalOpen}
    onClose={() => props.setIsDomainModalOpen(false)}
    title="Select a Domain"
    className=' mt-20 md:mt-0'
    size="lg"
    overlayOpacity={0.55}
    overlayBlur={3}
  >
    <div className="flex flex-col items-center p-4">
      <div className="w-full max-w-md">
        <div className="mb-2 text-sm font-medium text-gray-700">Stores:</div>
        <div className="overflow-y-auto w-full h-64 py-2 px-3 border border-gray-300 bg-white rounded-lg shadow-sm">
          {uniqueDomains.map((domain) => (
            <div
              key={domain}
              onClick={() => handleDomainClick(domain)}
              className={`cursor-pointer p-2 hover:bg-blue-100 ${
                props.domainFilter.includes(domain) ? "bg-blue-200" : ""
              }`}
            >
              {domain}
            </div>
          ))}
        </div>
      </div>
    </div>
  </Modal>
</>


        )

    }

}
