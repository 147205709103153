import { } from '@mantine/core';

export function Sort(props: any) {
    return (
        <>
            <div className="max-w-[300px] flex items-center max-w-[700px] mx-auto"> {/* Align items vertically center */}
                <div className="mx-2 text-sm font-medium text-gray-500">
                    Sort:
                </div>
                <select
                    id="sortType"
                    className="block my-1 py-2 px-2 pr-8 border border-gray-300 bg-white rounded-lg focus:outline-none focus:ring-bluelogo focus:border-bluelogo sm:text-xs text-xs " // Adjusted padding right (pr-8)
                    value={props.sortType}
                    onChange={(e) => props.setSortType(e.target.value)}
                >
                    <option value="noSort"> Recommended</option>
                    <option value="ratingsHighToLow">Ratings High to Low</option>
                    <option value="ratingsLowToHigh">Ratings Low to High</option>
                    <option value="priceLowToHigh">Price Low to High</option>
                    <option value="priceHighToLow">Price High to Low</option>
                </select>
            </div>
        </>
    )
}
