import { Text, Paper, ActionIcon, Group } from '@mantine/core';
import { Card, Image, Badge, Button, Modal } from '@mantine/core';
import posthog from 'posthog-js';
import {useEffect, useState} from 'react';

import { extractHostname } from './utils';
import {  Collapse, Box, MantineNumberSize } from '@mantine/core';
import { useDisclosure, useToggle } from '@mantine/hooks';
import styles from "../styles/Search.module.css";

import { ProductPreview } from './ProductPreview';
import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import { AwesomeButton } from 'react-awesome-button';
import { FiShoppingCart } from 'react-icons/fi';
import { FaHeart, FaMessage } from 'react-icons/fa6';
import { ClarosSummary } from './ClarosSummary';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

const extractNumber = (str, pattern) => {
    const match = str.match(pattern);
    return match ? match[0] : null;
};

let numberPattern = /\d+(\.\d+)?|\.\d+/

// Function to handle the display of rating and review count
const displayRatingOrReview = (data) => {
    if (typeof data === 'string') {
        return extractNumber(data, numberPattern);
    } else if (typeof data === 'number') {
        return data.toLocaleString();
    } else {
        return null;
    }
};

export function ProductCard(props) {
    const [opened, { toggle }] = useDisclosure(false);
    const [productInfo, setProductInfo] = useState({
        title: props.title, images: [props.image], price: props.price, rating: props.rating, link: props.link, description: "", category: [], delivery: [], bulletPoints: []
    });
    const context = useContext(PageContext)

    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
    }, []);

      // Function to fetch image URL for a product
      const fetchImageUrl = async (productTitle) => {
        try {
            const response = await fetch(API_URL + 'getProductImageUrl', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productTitle }),
            });
            const data = await response.json();
            return data.imageUrl;
        } catch (error) {
            console.error('Error fetching image URL:', error);
            return null;
        }
    };

    // useEffect(() => {
    //     // Check if the index is between 0 and 4 and image URL is missing or has 'placeholder' in it
    //     if (props.index >= 0 && props.index < 4 && (!props.image || props.image.includes('placeholder'))) {
    //         const updateImageUrl = async () => {
    //             const imageUrl = await fetchImageUrl(props.title);
    //             if (imageUrl) {
    //                 setProductInfo(prevInfo => ({
    //                     ...prevInfo,
    //                     images: [imageUrl]
    //                 }));
    //             }
    //         };
    //         updateImageUrl();
    //     }
    //     // ... other useEffect code
    // }, [props.index, props.image, props.title]);


    async function fetchProductInfo() {
        await fetch(API_URL + '/getAmazonProduct', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query : props.sku })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            let res = data.results;
           //add keys not in prodctInfo to productInfo
            let newProductInfo = productInfo;
            newProductInfo.description = res.description;
            newProductInfo.category = res.category;
            newProductInfo.delivery = res.delivery;
            newProductInfo.bulletPoints = res.bulletPoints;
            setProductInfo(newProductInfo);
            // handle the response data here
        })
        .catch(error => {
            // handle any errors here
        });
    }



    {/* log click and go to link */}
    async function handleClick(link) {
        // console.log('clicked');
        posthog.capture('product click', { product: link, storeId: context.storeId})
        if (context.isMainPage) {
            toggle();
        } else {
            window.open(link, "_blank");
        }
    }
    // console.log(props)

    let toggleButtonClicked  = () => {
        toggle();
    }

    const AFFILIATE_LINK = context.affiliateLink;


    let url;
    if (AFFILIATE_LINK !== '') {
        url = props.link + `?${AFFILIATE_LINK}&utm_source=claros`;
    } else {
        url = props.link + '?utm_source=claros';
    }

        return (
                <>
                {opened && (
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto" >
                    <ProductPreview
                        opened={opened}
                        toggle={toggle}
                        title={props.title}
                        price={props.price}
                        images={productInfo.images}
                        link={url}
                        rating={props.rating}
                        sku={props.sku}
                        description={productInfo.description}
                        category={productInfo.category}
                        delivery={productInfo.delivery}
                        bulletPoints={productInfo.bulletPoints}
                        aiSummary={props.aiSummary}
                        key={props.index}
                        handleProductSpecificSearch={props.handleProductSpecificSearch}

                    />
                    </div>
                )}
                <div className="flex-shrink-0 w-[55vw] sm:w-1/2 lg:w-4/12 pb-6 pl-2 pr-2 overflow-visible max-w-[300px]" id="product-card">
                    <div className="group relative cursor-pointer" onClick={() => handleClick(url)}>

                        <div className="transform sm:group-hover:scale-102 lg:group-hover:scale-105 group-hover:ring-2 group-hover:ring-blueoutline group-focus:ring-2 transition duration-300 ease-in-out rounded-xl border-[1px] border-bluelogo shadow-none shadow-blueshadow  tracking-wide leading-8 overflow-visibl bg-white" >

                            {/* Badges */}
                            {props.product.best_seller && (
                                <Badge color="teal" className="absolute top-0 left-0 m-2">
                                    Best Seller
                                </Badge>
                            )}
                            {props.product.is_amazons_choice && (
                                <Badge color="blue" className="absolute top-0 right-0 m-2">
                                    Amazon's Choice
                                </Badge>
                            )}
                            {props.product.aiSummary && (
                                <Badge className="absolute m-2 text-bluelogo bg-bluelogolight">
                                    Claros Recommended
                                </Badge>
                            )}

                            <div className="bg-contain bg-no-repeat bg-center p-8 m-2 h-36 sm:h-60 md:h-60 lg:h-60 rounded-xl" style={{ backgroundImage: `url(${props.image})` }}>
                                {/* <img src={props.image} className="mx-auto" alt="Image not found" /> */}
                            </div>
                            <div className="p-3">
                                <h3 className="mt-1  text-sm sm:text-lg text-gray-900 line-clamp-3 sm:line-clamp-2">
                                    {props.title}
                                </h3>



                                <h3 className="mt-4  text-sm sm:text-base text-gray-500 flex justify-between">

                                <div>
                                    {props.reviews_count && (
                                        `${displayRatingOrReview(props.reviews_count)} reviews`
                                    )}
                                </div>
                                <div>
                                    {props.rating && `★ ${displayRatingOrReview(props.rating)}`}
                                </div>
                                </h3>
                                
                                <div className="flex justify-between">
                                    <div className='mt-4 text-md sm:text-lg text-gray-600 pr-2'>
                                        {props.price}
                                    </div>
                                    {context.isMainPage && (
                                        <div className='mt-4 text-xs sm:text-sm text-gray-500 pr-2'>
                                            { props.source ? props.source :  extractHostname(props.link)  }
                                        </div>
                                    )}
                                </div>


                                
                                


                                    <ClarosSummary
                                        aiSummary={props.aiSummary}
                                    />
                                    {/* {props.reviews_count && ( */}
                                    {/* )} */}

                            </div>


                            
                            <div className="absolute bottom-2 right-2">
                                    <div className="flex items-center">
                                    </div>
                            </div>


                        </div>
                    </div>
                </div>
            </>
        )


}

