// Mapping of ipapi country codes to Amazon domains
export const AmazonDomainMap = {
  AE: "ae",
  CA: "ca",
  CN: "cn",
  JP: "co.jp",
  GB: "co.uk",
  US: "com",
  AU: "com.au",
  BE: "com.be",
  BR: "com.br",
  MX: "com.mx",
  TR: "com.tr",
  DE: "de",
  ES: "es",
  FR: "fr",
  IN: "in",
  IT: "it",
  NL: "nl",
  PL: "pl",
  SE: "se",
};

// Function to return the Amazon domain based on the country code
export function getAmazonDomain(countryCode: string) {
  const domain = AmazonDomainMap[countryCode.toUpperCase()];
  if (domain) {
    console.log("found amazon domain")
    return domain;
  } else {
    console.log(`${countryCode} country not supported. Using US marketplace`);
    return "com"; // Default to US marketplace
  }
}

