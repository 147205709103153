import { useState } from 'react';
import { createStyles, Header, Container, Group, Burger, Title, Text, Paper, Transition, Image } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { HeaderInput } from './HeaderInput';
import { PageContext } from '../../contexts/pageContext';
import { useContext } from 'react';
import { AwesomeButton } from "react-awesome-button";
import TopBanner from '../TopBanner';


const HEADER_HEIGHT = 'auto';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    height: 'auto',
    borderBottom: `0px`,
    backgroundColor: 'white',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },


}));

const ButtonStyles = {
  awsBtn: {
      // '--button-secondary-color': '#ebfbee',
      /* --button-secondary-color-light: #1e88e5; */
      // '--button-secondary-color-active': '#bed1c2',
      // '--button-secondary-border': '2px solid #40bf56',
      '--button-horizontal-padding': '0px', // Override the value here
      fill:"none"

  }
};

// style={{color:inherit ; text-decoration:none}}
export function HeaderResponsive(props: any) {

  const context = useContext(PageContext);
  const { classes, cx } = useStyles();

  return (
    <div className={context.isMainPage? "bg-bgwhite" : ""}>
      {(context.id != "stonetooling" && context.id != "oxford") && (
      <>

      {/* <TopBanner /> */}
      <div className={` flex items-center justify-between pt-4 `} style={{ zIndex: 1 }} >
          { (context.isMainPage) ? (
            <>

            {/* <div className="flex items-center">
                <a href="/landingpage" className="flex items-center">
                    <img src="../../logo-circle.png" className="h-16 sm:h-16 mr-3 " alt="Claros Logo" />
                    <span className="self-center text-4xl font-semibold whitespace-nowrap  text-bluelogo ">Claros</span>
                </a>
            </div> */}
            </>
          ) : (
            context.logoUrl != "" && (
              <a href={props.website} >
                  <Image src={props.logo} fit="contain" radius="lg" width={context.logoWidth} height={context.logoHeight} alt="logo" />
              </a>
            )
          )}
      </div>

      </>

      )}




        <HeaderInput
            updateTopic={props.updateTopic}
            topic={props.topic}
            topicSelected={props.topicSelected}
            setTopicSelected={props.setTopicSelected}
            updateLocation={props.updateLocation}
            location={props.location}
            setLocationSelected={props.setLocationSelected}
            coords={props.coords}
            setCoords={props.setCoords}
            />


    </div>
  );
}
